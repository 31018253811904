<template>
  <TemplateBlock
    mod-class
    content-class="shop-my-orders"
    title-page="Мои заказы"
  >
    <div v-if="orders && orders.length" class="w-full">
      <div class="orders-list mt-3">
        <div
          v-for="order in orders"
          :key="order.id"
          class="order pseudo-link"
          title="Нажмите для просмотра информации"
        >
          <div
            class="order__header pseudo-link d-flex align-items-center justify-content-between"
            v-b-toggle="`collapse-${order.id}`"
          >
            <div class="left-side d-flex align-items-center">
              <div>Заказ от</div>
              <div class="ml-2 font-semibold">
                {{ order.createdAt | dateTimeWithoutLocale }}
              </div>
            </div>
            <div class="right-side">
              <b-badge :variant="getStatusVariant(order.status)">{{
                getStatusDisplayName(order.status)
              }}</b-badge>
            </div>
          </div>
          <b-collapse
            :visible="orders.length === 1"
            :id="`collapse-${order.id}`"
            class="order__body"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="left-side">
                <div
                  v-for="position in order.positions"
                  :key="position.id"
                  class="position d-flex align-items-center mb-2"
                >
                  <img
                    width="60"
                    height="50"
                    :src="
                      position.photo.length
                        ? position.photo.length
                        : 'https://placehold.it/60x50'
                    "
                  />
                  <div class="ml-2">{{ position.title }}</div>
                </div>
              </div>
              <div class="right-side">
                <div class="price">{{ getTotalPrice(order) | money }}</div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </TemplateBlock>
</template>

<script>
import RequestManager from "@/function/request/RequestManager";
import TemplateBlock from "@/components/TepmplateBlock.vue";

export default {
  name: "ShopMyOrders",
  components: {
    TemplateBlock
  },
  data() {
    return {
      currentUser: {},
      token: "",
      orders: []
    };
  },
  methods: {
    getStatusVariant(status) {
      let res = "";
      switch (status) {
        case "Open":
          res = "info";
          break;
      }
      return res;
    },
    getStatusDisplayName(status) {
      let res = "";
      switch (status) {
        case "Open":
          res = "Открыт";
          break;
      }
      return res;
    },
    getTotalPrice(order) {
      let res = 0;
      order.positions.forEach(position => {
        res = res + position.totalPrice;
      });
      return res;
    }
  },
  async created() {
    const currentUserSrc = localStorage.getItem("currentUser");
    if (currentUserSrc) {
      this.currentUser = JSON.parse(currentUserSrc);
    }
    if (this.currentUser.id) {
      const { items } = await RequestManager().shopOrderApi.listOrder({
        userIds: [this.currentUser.id.toString()]
      });
      this.orders = items;
    } else {
      this.token = this.$route.query.token;
      const { items } = await RequestManager().shopOrderApi.listOrder({
        ids: [this.token]
      });
      this.orders = items;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .container {
  justify-content: flex-start;
  align-items: flex-start;
}

.font-semibold {
  font-weight: 600;
}

.w-full {
  width: percentage(1);
}

.flex-1 {
  flex: 1;
}

.pseudo-link {
  cursor: pointer;
}

.order__header {
  padding: 8px 16px;
  margin-top: 8px;
  background: var(--main-card-color);
  border-radius: 6px;
}

.order__body {
  background-color: var(--main-card-color);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 8px 16px;
}

.price {
  font-size: 1.2em;
  font-weight: 600;
}

.position img {
  border-radius: 6px;
}
</style>
